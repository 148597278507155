<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Part">
            <validation-observer ref="updatePartValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Category"
                      label-for="category"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="category"
                        rules="required"
                      >
                        <b-form-select
                          v-model="category_id"
                          :options="categoryOptions"
                          @change="getBrands"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Brand"
                      label-for="brand"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="brand"
                        rules="required"
                      >
                        <b-form-select
                          v-model="brand_id"
                          :options="brandOptions"
                          :disabled="!category_id"
                          @change="getModels"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Model"
                      label-for="model"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="model"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.mobile_model_id"
                          :options="options"
                          :disabled="!brand_id"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter part name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Admin Name"
                      label-for="admin_name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="admin_name"
                      >
                        <b-form-input
                          id="admin_name"
                          v-model="form.admin_name"
                          name="name"
                          placeholder="Enter part admin name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Price"
                      label-for="price"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="price"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.price"
                          type="number"
                          placeholder="Enter Price"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Quantity"
                      label-for="quantity"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="quantity"
                      >
                        <b-form-input
                          v-model="form.quantity"
                          type="number"
                          placeholder="Enter Quantity"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Required Time"
                      label-for="time_required"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="time_required"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.time_required"
                          :options="timeOptions"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Order Time"
                      label-for="order_time"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="order_time"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.order_time"
                          placeholder="Order time"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Priority"
                      label-for="priority"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="priority"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.priority"
                          placeholder="Priority"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-1"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="form.required_color"
                        class="custom-control-success"
                      >
                        Required Color
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="form.required_color">
                  <b-col
                    v-for="(color, index) in colors"
                    :key="index"
                  >
                    <div>
                      <div>
                        <b-form-group>
                          <validation-provider
                            v-slot="{ errors }"
                          >
                            <b-form-checkbox
                              v-model="colorsRequired[index]"
                              class="custom-control-success"
                            >
                              {{ color.name }}
                            </b-form-checkbox>
                            <span class="text-danger error-msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Popup Information"
                      label-for="popup_information"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="popup_information"
                      >
                        <b-textarea
                          id="popup_information"
                          v-model="form.popup_information"
                          name="popup_information"
                          placeholder=""
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-1"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="form.is_upsale"
                        class="custom-control-success"
                        @change="form.upsale_description = '' && form.is_upsale"
                      >
                        Upsale
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="form.only_upsale"
                        class="custom-control-success ml-2"
                      >
                        Only Upsale
                      </b-form-checkbox>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Upsale Description"
                      label-for="upsale_description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="upsale description"
                      >
                        <b-textarea
                          id="upsale_description"
                          v-model="form.upsale_description"
                          name="upsale_description"
                          :disabled="!form.is_upsale"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      category_id: null,
      brand_id: null,
      colors: [],
      colorsRequired: [],
      form: {
        mobile_model_id: null,
        name: '',
        admin_name: '',
        priority: '',
        quantity: '',
        price: '',
        time_required: null,
        order_time: '',
        popup_information: '',
        is_upsale: false,
        only_upsale: false,
        upsale_description: '',
        required_color: false,
        colors_for_parts: [],
        mobile_color: [],
      },
      categoryOptions: [
        { value: null, text: 'Please select category', disabled: true },
      ],
      brandOptions: [
        { value: null, text: 'Please select brand', disabled: true },
      ],
      options: [
        { value: null, text: 'Please select model', disabled: true },
      ],
      timeOptions: [
        { value: null, text: 'Please select one option', disabled: true },
        { value: '30 min', text: '30 min' },
        { value: '1 Hour', text: '1 Hour' },
        { value: '1.5 Hour', text: '1.5 Hour' },
        { value: '2 Hour', text: '2 Hour' },
        { value: '2.5 Hour', text: '2.5 Hour' },
        { value: '3 Hour', text: '3 Hour' },
        { value: '3.5 Hour', text: '3.5 Hour' },
        { value: '4 Hour', text: '4 Hour' },
        { value: '15-30 min', text: '15-30 min' },
        { value: '30-60 min', text: '30-60 min' },
        { value: '1-2 Hour', text: '1-2 Hour' },
        { value: '2-3 Hour', text: '2-3 Hour' },
        { value: '1-3 Hour', text: '1-3 Hour' },
        { value: '2-4 Hour', text: '2-4 Hour' },
        { value: '3-4 Hour', text: '3-4 Hour' },
      ],
      currentPart: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchData()
    this.loader = false
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_CATEGORY', 'SHOW_BRANDS_BY_CATEGORY', 'SHOW_MODELS_BY_BRAND', 'FETCH_SINGLE_PART', 'SHOW_ALL_COLORS_BY_MODEL_ID', 'UPDATE_PART']),

    async fetchData() {
      try {
        this.loader = true
        const resp = await this.SHOW_ALL_CATEGORY()
        resp.forEach(element => {
          this.categoryOptions.push({ value: element.id, text: element.name })
        })
        // Fetch Part Data
        this.currentPart = await this.FETCH_SINGLE_PART(this.$route.params.id)
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.currentPart) {
          if (Object.hasOwnProperty.call(this.form, key)) {
            this.form[key] = this.currentPart[key]
          }
        }
        this.form.is_upsale = this.form.is_upsale === '1'
        this.form.only_upsale = this.form.only_upsale === 1
        this.form.required_color = this.form.required_color === '1'
        this.category_id = this.currentPart.mobile_models.mobile_category_id
        this.brand_id = this.currentPart.mobile_models.mobile_brand_id

        // Set Colors
        this.getColors()
        if (this.currentPart.colors_for_parts) {
          this.colorsRequired = []
          this.colorsRequired = this.currentPart.colors_for_parts.map(item => item.isSelected)
        } else {
          this.colorsRequired = this.colors.map(() => true)
        }

        // Fetch Brands
        const brandResponse = await this.SHOW_BRANDS_BY_CATEGORY(this.category_id)
        brandResponse.forEach(element => {
          this.brandOptions.push({ value: element.id, text: element.name })
        })

        // Fetch Models
        const modelResponse = await this.SHOW_MODELS_BY_BRAND(this.brand_id)
        modelResponse.forEach(element => {
          this.options.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getBrands() {
      try {
        this.loader = true
        this.brandOptions = [
          { value: null, text: 'Please select brand', disabled: true },
        ]
        this.brand_id = null
        const resp = await this.SHOW_BRANDS_BY_CATEGORY(this.category_id)
        resp.forEach(element => {
          this.brandOptions.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getModels() {
      try {
        this.loader = true
        this.options = [
          { value: null, text: 'Please select model', disabled: true },
        ]
        this.form.mobile_model_id = null
        const resp = await this.SHOW_MODELS_BY_BRAND(this.brand_id)
        resp.forEach(element => {
          this.options.push({ value: element.id, text: element.name })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    async getColors() {
      try {
        this.loader = true
        const resp = await this.SHOW_ALL_COLORS_BY_MODEL_ID(this.form.mobile_model_id)
        this.colors = resp
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updatePartValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            this.form.admin_name = this.form.admin_name === '' ? this.form.name : this.form.admin_name
            this.form.colors_for_parts = []
            if (!this.form.required_color) {
              this.colors.forEach(element => {
                this.form.colors_for_parts.push({ ...element, isSelected: false })
                this.form.mobile_color.push(element.id)
              })
            } else {
              this.colors.forEach((element, index) => {
                if (this.colorsRequired[index]) {
                  this.form.colors_for_parts.push({ ...element, isSelected: true })
                  this.form.mobile_color.push(element.id)
                } else {
                  this.form.colors_for_parts.push({ ...element, isSelected: false })
                }
              })
            }
            const newFrom = { ...this.form }
            if (this.currentPart.name === this.form.name) {
              delete newFrom.name
            }
            const resp = await this.UPDATE_PART({ id: this.$route.params.id, data: newFrom })
            if (resp) {
              this.$nextTick(() => {
                this.$refs.updatePartValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Part updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The part has been updated successfully!',
                },
              })
              this.$router.push('/mobile-tablet/parts')
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
